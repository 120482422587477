<template>
  <div class="page my-16 flex flex-col gap-10 md:gap-20 container">
    <section class="max-w-[840px] flex flex-col gap-3">
      <h1 class="font-bold text-xl md:text-7xl">Каталог видео</h1>
      <p class="text-sm md:text-lg">
        «Смотри Заполярье» — удобная коллекция роликов, отсортированных по
        категориям. Здесь вы найдете материалы о природе, культуре, истории и
        жизни северных регионов, собранные в тематические папки для быстрого
        поиска. Погрузитесь в уникальные истории и красоту Севера через удобный
        и структурированный раздел!
      </p>
    </section>
    <section class="flex flex-col gap-8">
      <div class="filters flex items-end gap-5 flex-wrap md:flex-nowrap">
        <FormKit v-model="filters" type="form" :actions="false">
          <div class="flex gap-5">
            <FormKitSchema :schema="filtersScheme" />
          </div>
        </FormKit>
        <FormKit
          v-model="filters.search"
          type="search"
          placeholder="Поиск"
          :classes="{ outer: 'w-full' }"
        >
          <template #prefix="context">
            <IconSearch class="flex-none size-4 ml-3" />
          </template>
          <template #suffix="context">
            <button
              v-if="filters.search"
              class="flex-none pl-1 py-2 pr-3"
              @click="filters.search = ''"
            >
              <IconX class="size-4" />
            </button>
          </template>
        </FormKit>
      </div>
      <div class="catalog">
        <DataProvider
          v-slot="{ isFetchingNextPage, data, isFetchedAfterMount, isFetching }"
          :filters="filters"
          :q-key="`${root}-index`"
          :endpoint="`/${root}`"
        >
          <div
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-y-6"
          >
            <template v-for="(page, i) in data.pages" :key="i">
              <VideoCard
                v-for="item in page.resources"
                :key="item.id"
                :context="item"
                type="catalog"
                :to="`/videos/${item.id}`"
                :is-loading="
                  isFetching && !isFetchingNextPage && isFetchedAfterMount
                "
                @subscribe="subscribe"
              />
            </template>
          </div>
        </DataProvider>
      </div>
    </section>
  </div>
  <UIFooter />
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue'

import { IconSearch, IconX } from '@tabler/icons-vue'

import VideoCard from '@/components/common/VideoCard.vue'
import DataProvider from '@/components/DataProvider.js'
import UIFooter from '@/components/ui/UIFooter.vue'
import useInfinitePage from '@/plugins/useInfinitePage.js'

const http = inject('http')
const { root, filters, subscribe } = useInfinitePage('albums')

const tags = ref([])
const filtersScheme = computed(() => [
  {
    $formkit: 'qselect',
    name: 'tags',
    placeholder: 'Праздники',
    label: 'Категории',
    trackBy: 'id',
    labelBy: 'name',
    options: tags.value,
    classes: {
      outer: 'md:w-[286px]',
    },
  },
  {
    $formkit: 'date',
    name: 'published_at',
    placeholder: 'Год',
    trackBy: 'id',
    label: 'Дата публикации',
    labelBy: 'Дата публикации',
    minDate: new Date(2015, 0, 1),
    maxDate: new Date(2100, 0, 1),
    startDate: new Date(2015, 0, 1),
    classes: {
      outer: 'md:w-[286px]',
    },
  },
])

const getTags = async () => {
  const tags = await http('/api/common/types/tags/albums')
  if (tags) {
    return tags.resources
  }
}

onMounted(async () => {
  tags.value = await getTags()
})
</script>
