import { watchPausable } from '@vueuse/core'
import { last } from 'lodash-es'
import {
  computed,
  defineComponent,
  h,
  inject,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue'
import { useInfiniteQuery } from 'vue-query'
import { useRoute, useRouter } from 'vue-router'

import FetchProvider from '@/components/FetchProvider.vue'
import { lzCompress } from '@/utils/global.js'

const DataProvider = defineComponent({
  name: 'DataProvider',
  props: {
    perPage: null,
    modelValue: {},
    filters: {},
    extendFilters: {},
    endpoint: {},
    dependant: {},
    qKey: {},
    paginationType: {
      type: String,
      default: 'infinite',
    },
  },
  emits: ['update:model-value'],
  slots: {
    default: {},
    error: {},
    empty: {},
    button: {},
  },
  setup(props, { slots, emit, expose }) {
    const http = inject('http')
    const router = useRouter()
    const route = useRoute()
    const checkResources = computed(() => {
      return data.value?.pages ? last(data.value.pages).total : 0
    })

    const enabled = computed(() => {
      return props.dependant
    })

    const fetchResources = async ({ pageParam = 1 }) => {
      return await http(props.endpoint, {
        query: {
          filters: filters.value.replaceAll(' ', '+'),
          page: pageParam,
          per_page: props.perPage,
        },
      })
    }

    const currentPage = ref(0)

    function useResourceQuery({ enabled }) {
      return useInfiniteQuery(props.qKey, fetchResources, {
        enabled,
        getNextPageParam: (lastPage, pages) => {
          currentPage.value = lastPage.current_page
          return lastPage.current_page + 1 > lastPage.last_page
            ? undefined
            : lastPage.current_page + 1
        },
      })
    }

    const {
      isIdle,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      data,
      dataUpdatedAt,
      error,
      errorUpdateCount,
      errorUpdatedAt,
      failureCount,
      failureReason,
      fetchStatus,
      isError,
      isFetched,
      isFetchedAfterMount,
      isFetching,
      isInitialLoading,
      isLoading,
      isLoadingError,
      isPaused,
      isPlaceholderData,
      isPreviousData,
      isRefetchError,
      isRefetching,
      isStale,
      isSuccess,
      refetch,
      remove,
      status,
    } = useResourceQuery({ enabled, pageParam: 50 })

    expose({ checkResources, isLoading })

    const compressExtend = lzCompress(props.extendFilters)

    const filters = ref(route.query.filters || compressExtend)

    const watcher = watchPausable(
      () => [props.filters, props.extendFilters],
      (v) => {
        filters.value = lzCompress({
          ...props.filters,
          ...props.extendFilters,
        })

        router.replace({
          query: {
            filters: filters.value,
          },
        })
        refetch.value()
      },
    )
    watcher.pause()

    watch(
      () => data.value,
      () => {
        emit('update:model-value', data.value)
      },
    )

    const slotExport = reactive({
      isIdle,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      data,
      dataUpdatedAt,
      error,
      errorUpdateCount,
      errorUpdatedAt,
      failureCount,
      failureReason,
      fetchStatus,
      isError,
      isFetched,
      isFetchedAfterMount,
      isFetching,
      isInitialLoading,
      isLoading,
      isLoadingError,
      isPaused,
      isPlaceholderData,
      isPreviousData,
      isRefetchError,
      isRefetching,
      isStale,
      isSuccess,
      refetch,
      remove,
      status,
    })

    onMounted(() => {
      watcher.resume()
    })

    return () =>
      h(
        FetchProvider,
        {
          isLoading: isLoading.value || isIdle.value,
          isFetching: isFetching.value,
          isError: isError.value,
          fetchNextPage: fetchNextPage.value,
          isFetchingNextPage: isFetchingNextPage?.value,
          isFiltered: !!filters.value,
          hasNextPage: hasNextPage.value,
          dataLength: checkResources.value,
          paginationType: props.paginationType,
          currentPage: currentPage.value,
        },
        {
          default: () => slots.default(slotExport),
          button: () =>
            slots.hasOwnProperty('button') ? slots.button() : null,
          empty: () => (slots.hasOwnProperty('empty') ? slots.empty() : null),
          error: () => (slots.hasOwnProperty('error') ? slots.error() : null),
        },
      )
  },
})

export default DataProvider
