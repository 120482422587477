<template>
  <div
    class="relative"
    :class="[
      { 'ml-4 sm:ml-10': context.level === 1 },
      { 'ml-8 sm:ml-20': context.level === 2 },
      { 'ml-12 sm:ml-28': context.level === 3 },
      { 'hide-child-border': context.isReply && !context.level },
    ]"
  >
    <div class="flex items-start pt-5 pb-2">
      <!--      <div-->
      <!--        class="w-14 h-14 rounded-full flex flex-none mr-4 bg-black-100 relative z-10"-->
      <!--      >-->
      <!--        <IconCameraSelfie class="m-auto text-black-300" />-->
      <!--      </div>-->
      <div class="flex flex-1 flex-col items-start">
        <div class="flex justify-between w-full mb-2">
          <div>
            <span
              class="text-base font-bold leading-5 text-black-900 dark:text-white"
              v-text="context.user.title"
            />
          </div>

          <div class="flex items-center">
            <div v-if="replyTo" class="flex items-center mr-2">
              <IconArrowForwardUp
                class="w-6 h-6 text-black-400 dark:text-black-300 mr-2"
              />
              <span
                class="text-black-700 dark:text-black-300 text-base font-bold"
                v-text="replyTo.title"
              />
            </div>
            <button
              v-if="
                userId === context.user.id &&
                removeChecker &&
                !context.comments_count > 0 &&
                global.resources.comments.can_be_published
              "
              class="text-black-400 dark:text-black-300 transition-colors hover:text-rose-400"
              @click="$emit('remove', context.id)"
            >
              <IconX class="w-6 h-6" />
            </button>
          </div>
        </div>
        <div
          class="text-base leading-8 text-black-900 dark:text-black-100 mb-3"
          v-text="
            context.body ||
            'Отличные новости! Пусть чаще выделяют бюджет на нашу область'
          "
        />

        <div class="flex flex-1 items-center justify-between w-full">
          <div class="flex gap-x-3">
            <span class="text-sm text-black-600" v-text="timeago" />
            <button
              v-if="
                context.level < 3 && global.resources.comments.can_be_published
              "
              class="text-sm leading-5 text-primary-700 dark:text-brand-1"
              @click="reply = !reply"
              v-text="reply ? 'Отменить' : 'Ответить'"
            />
          </div>
          <Reactions
            :personal="context.reactions.personal"
            :global="context.reactions.global"
            position="left"
            @set-reaction="
              (type) => $emit('set-reaction', { id: context.id, type })
            "
          />
        </div>
        <button
          v-if="context.comments_count"
          class="cursor-pointer flex items-center min-h-[44px] text-sm transition-colors justify-center rounded-lg w-full p-3"
          :class="
            !context.isReply
              ? 'bg-black-50 dark:bg-primary-950 hover:bg-primary-50'
              : 'bg-primary-50 dark:bg-primary-900 text-primary'
          "
          @click="getReplies"
        >
          <span
            v-if="!isRepliesLoading"
            v-text="
              context.isReply
                ? `Свернуть ответы (${context.comments_count})`
                : `Развернуть ответы (${context.comments_count})`
            "
          />
          <LoadingIcon
            v-if="isRepliesLoading"
            class="text-primary size-4 ml-2"
          />
        </button>
        <div v-if="reply" class="flex w-full items-start mt-3">
          <!--          <div-->
          <!--            class="w-14 h-14 rounded-full flex flex-none mr-4 bg-black-100 relative z-10"-->
          <!--          >-->
          <!--            <IconCameraSelfie class="m-auto text-black-300" />-->
          <!--          </div>-->
          <div class="w-full flex mt-1 ml-4 sm:ml-10 max-sm:flex-col">
            <textarea
              ref="textarea"
              v-model="replyInput"
              class="resize-none flex-1 py-2 sm:py-3 px-3 sm:px-4 ring-1 ring-black-200 focus:ring-primary text-black dark:text-white dark:bg-primary-950 dark:ring-primary-900 outline-none rounded max-h-[250px]"
              placeholder="Напишите ответный комментарий"
            />
            <button
              class="bg-primary hidden sm:flex rounded-lg w-12 h-12 ml-4"
              @click="sendComment"
            >
              <Transition mode="out-in">
                <IconSend class="text-white m-auto size-6" />
              </Transition>
            </button>
            <UIButton
              class="mt-2 w-full sm:hidden !bg-primary"
              @click="sendComment"
              title="Отправить"
            />
          </div>
        </div>
      </div>

      <!--      <div v-if="context.isReply" class="w-14 absolute top-5 h-full">-->
      <!--        <div-->
      <!--          class="flex-none border-l border-solid border-black-100 absolute top-0 left-1/2 -translate-x-1/2 z-0 h-full"-->
      <!--        />-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script setup>
import { useTimeAgo } from '@vueuse/core'
import dayjs from 'dayjs'
import { computed, inject, ref } from 'vue'

import { useGlobalStore } from '@/store/global.js'

import {
  IconArrowForwardUp,
  IconCameraSelfie,
  IconSend,
  IconX,
} from '@tabler/icons-vue'

import IconLoading from '@/components/loading/LoadingIcon.vue'
import LoadingIcon from '@/components/loading/LoadingIcon.vue'
import Reactions from '@/components/Reactions.vue'
import UIButton from '@/components/ui/UIButton.vue'
import { plural } from '@/utils/global.js'

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
  userId: {
    type: [String, Number],
    default: null,
  },
  setReaction: {
    type: Function,
    defaut: null,
  },
  level: {
    type: Number,
    default: null,
  },
  replyTo: {
    type: Object,
    default: null,
  },
})

const emits = defineEmits([
  'get-replies',
  'set-reaction',
  'send-reply',
  'remove',
])

const global = useGlobalStore()

const removeChecker = computed(() => {
  return dayjs(props.context.created_at).add(1, 'hour').isAfter(dayjs())
})

const timeago = useTimeAgo(props.context.created_at, {
  fullDateFormatter: (date) => date.toLocaleDateString(),
  messages: {
    justNow: 'только что',
    past: (n) => (n.match(/\d/) ? `${n} назад` : n),
    future: (n) => (n.match(/\d/) ? `${n} в` : n),
    month: (n, past) =>
      n === 1
        ? past
          ? 'в прошлом месяце'
          : 'в следующем месяце'
        : `${n} ${plural(n, ['месяц', 'месяца', 'месяцев'])}`,
    year: (n, past) =>
      n === 1
        ? past
          ? 'в прошлом году'
          : 'в следующем году'
        : `${n} ${plural(n, ['год', 'года', 'лет'])}`,
    day: (n, past) =>
      n === 1
        ? past
          ? 'вчера'
          : 'завтра'
        : `${n} ${plural(n, ['день', 'дня', 'дней'])}`,
    week: (n, past) =>
      n === 1
        ? past
          ? 'на прошлой неделе'
          : 'на следующей неделе'
        : `${n} ${plural(n, ['неделю', 'недели', 'недель'])}`,
    hour: (n) => `${n} ${plural(n, ['час', 'часа', 'часов'])}`,
    minute: (n) => `${n} ${plural(n, ['минуту', 'минуты', 'минут'])}`,
    second: (n) => `${n} ${plural(n, ['секунду', 'секунды', 'секунд'])}`,
  },
})

const reply = ref(false)
const replyInput = ref('')
const isRepliesLoading = ref(false)

function repliesLoading() {
  isRepliesLoading.value = !isRepliesLoading.value
}

function getReplies() {
  if (!props.context.isReply) {
    repliesLoading()
  }
  emits('get-replies', props.context.id, repliesLoading)
}

function sendComment() {
  emits('send-reply', props.context.id, replyInput.value)
  replyInput.value = ''
  reply.value = false
}
</script>
