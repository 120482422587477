<template>
  <div class="page my-16 flex flex-col gap-16 container">
    <section class="flex flex-col md:flex-row items-centet gap-3 w-full">
      <div class="max-w-[1052px] w-full flex flex-col gap-3">
        <h1 class="font-bold md:text-7xl content-center">Смотри Заполярье</h1>
        <p class="max-w-[840px] md:text-[18px]">
          «Смотри Заполярье» — это раздел, где вы найдете уникальные видео о
          жизни, природе и культуре северных регионов. Познакомьтесь с красотой
          суровых пейзажей, традициями коренных народов и современными
          достижениями Заполярья. Погрузитесь в атмосферу Севера через яркие и
          захватывающие кадры!
        </p>
      </div>
      <div
        class="max-h-[70px] max-w-[184px] w-full flex flex-col md:items-end justify-end"
      >
        <RouterLink :to="{ name: 'videos' }">
          <Button
            :icon="IconVideo"
            size="md"
            title="Каталог видео"
            class="h-[52px] flex gap-2 items-center"
          />
        </RouterLink>
      </div>
    </section>
    <section class="md:flex gap-5 items-start">
      <MainVideo
        v-if="Object.values(mainVideo).length"
        :src="`https://video-cdn.gov-murman.ru/${mainVideo.external_id}`"
        :context="mainVideo"
      />
    </section>
    <section class="flex flex-col gap-8">
      <div class="filters flex items-end gap-5 flex-wrap md:flex-nowrap">
        <FormKit v-model="filters" type="form" :actions="false">
          <div class="flex gap-5">
            <FormKitSchema :schema="filtersScheme" />
          </div>
        </FormKit>
        <FormKit
          v-model="filters.search"
          type="search"
          placeholder="Поиск"
          :classes="{ outer: 'w-full' }"
        >
          <template #prefix="context">
            <IconSearch class="flex-none size-4 ml-3" />
          </template>
          <template #suffix="context">
            <button
              v-if="filters.search"
              class="flex-none pl-1 py-2 pr-3"
              @click="filters.search = ''"
            >
              <IconX class="size-4" />
            </button>
          </template>
        </FormKit>
      </div>
      <div class="catalog">
        <DataProvider
          v-model="catalog"
          v-slot="{ isFetchingNextPage, data, isFetchedAfterMount, isFetching }"
          :filters="filters"
          :q-key="`${root}-index`"
          :endpoint="`/${root}`"
        >
          <div
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-y-6"
          >
            <template v-for="(page, i) in data.pages" :key="i">
              <VideoCard
                v-for="item in page.resources"
                :key="item.id"
                :context="{
                  ...item,
                  title: item.name,
                  subtitle: item.description,
                }"
                type="catalog"
                :to="`/videos/${route.params.albumId}/${item.external_id}`"
                :is-loading="
                  isFetching && !isFetchingNextPage && isFetchedAfterMount
                "
                @click="
                  openModalVideo({
                    id: item.id,
                    external_id: item.external_id,
                    title: item.name,
                    description: item.description,
                    reactions: item.reactions,
                    source: item.poster,
                  })
                "
              />
            </template>
          </div>
        </DataProvider>
      </div>
    </section>
  </div>
  <UIFooter />
</template>

<script setup lang="ts">
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import { RouterLink, useRoute, useRouter } from 'vue-router'

import { IconSearch, IconVideo, IconX } from '@tabler/icons-vue'

import DataProvider from '@/components/DataProvider.js'
import Button from '@/components/ui/UIButton.vue'
import UIFooter from '@/components/ui/UIFooter.vue'
import useInfinitePage from '@/plugins/useInfinitePage.js'
import useModalDynamic from '@/plugins/useModalDynamic.js'

import MainVideo from '../../components/common/MainVideo.vue'
import VideoCard from '../../components/common/VideoCard.vue'
import VideoModal from '../../components/modals/VideoModal.vue'

type Resource = {
  id: number
  name: string
  description: string | null
  external_id: string | null
  poster: string | null
  preview: string | null
  reactions: {
    global: {
      wow: number
      lovely: number
      sad: number
      angry: number
    }
    personal: string[]
  }
  tags: string[]
  visibility: boolean
}

type Context = Partial<Resource> & {
  title: string
}

const http = inject('http')

const router = useRouter()
const route = useRoute()

const { root, filters } = useInfinitePage(
  `albums/${route.params.albumId}/videos`,
)

const catalog = ref(null)
const tags = ref([])
const mainVideo = ref<Resource | {}>({})
const resourceMainVideo = ref({})

const filtersScheme = computed(() => [
  {
    $formkit: 'qselect',
    name: 'tags',
    placeholder: 'Праздники',
    label: 'Категории',
    trackBy: 'id',
    labelBy: 'name',
    options: tags.value,
    classes: {
      outer: 'md:w-[286px]',
    },
  },
  {
    $formkit: 'date',
    name: 'published_at',
    placeholder: 'Год',
    trackBy: 'id',
    label: 'Дата публикации',
    labelBy: 'Дата публикации',
    minDate: new Date(2015, 0, 1),
    maxDate: new Date(),
    startDate: new Date(2015, 0, 1),
    yearPicker: true,
    classes: {
      outer: 'md:w-[286px]',
    },
  },
])

const openModalVideo = (context: Context) => {
  useModalDynamic({
    opened: true,
    component: VideoModal,
    compAttrs: {
      context,
      options: {},
    },
  })
}

const getTags = async () => {
  const tags = await http('/api/common/types/tags/albums')
  if (tags) {
    return tags.resources
  }
}

const validVideoParam = () => {
  const videoId = route.params.videoId as string
  if (videoId) {
    const video = findVideoById(videoId)

    if (video) {
      openModalVideo({
        id: video.id,
        external_id: video.external_id,
        title: video.name,
        description: video.description,
        reactions: video.reactions,
        source: video.poster,
      })
    } else {
      console.error('Видео не найдено')
    }
  }
}

const checkParams = () => {
  validVideoParam()
}

const getAllResources = () => {
  if (!catalog.value.pages || catalog.value.pages.length === 0) {
    return []
  }
  return catalog.value.pages.flatMap((page) => page.resources)
}

const findVideoById = (videoId: number | string) => {
  const resources = getAllResources()
  return resources.find((resource) => resource.external_id == videoId)
}

const getRandomVideo = () => {
  const resources = getAllResources()
  if (resources.length === 0) {
    throw new Error('Недостаточно ресурсов для выбора.')
  }

  const randomIndex = Math.floor(Math.random() * resources.length)
  mainVideo.value = { ...resources[randomIndex], visibility: false }
}

const loadDataPage = () => {
  return new Promise((resolve, _reject) => {
    setTimeout(() => {
      getRandomVideo()
      resolve(true)
    }, 3000)
  })
}

const showMainVideo = () => {
  mainVideo.value.visibility = true
}

onMounted(async () => {
  setTimeout(checkParams, 1500)
  loadDataPage().then(showMainVideo)
  tags.value = await getTags()
})
</script>
