<template>
  <div
    class="modal text-white space-y-5 m-auto max-w-[1248px] w-full bg-black rounded-lg overflow-hidden"
  >
    <iframe
      :src="videoSource"
      width="100%"
      height="620"
      frameborder="0"
      allowfullscreen
    />
    <!-- <VideoPlayer :src="''"/> -->
    <section class="px-10 pb-10">
      <div
        class="actions md:flex justify-between flex-row-reverse space-y-4 md:space-y-0"
      >
        <div class="space-x-4 flex flex-nowrap items-center max-h-fit">
          <Button
            size="xs"
            :type="
              video?.resource?.reactions.personal.includes('lovely')
                ? 'green'
                : 'gray'
            "
            :icon="IconHeart"
            :title="video?.resource?.reactions?.global?.lovely"
            class="flex text-sm gap-2"
            @click="sendReaction('lovely')"
          />
          <Button
            size="xs"
            type="gray"
            title="Поделиться"
            :icon="IconShare3"
            class="flex gap-2 text-sm"
            @click="openShareModal(href)"
          />
          <Button
            size="xs"
            type="gray"
            title="Скачать"
            :icon="IconDownload"
            class="flex gap-2 text-sm"
            @click="
              downloadFile(
                isObject(resource.origins)
                  ? Object.values(resource.origins)[0]
                  : resource.origins[0],
              )
            "
          />
        </div>
        <div class="space-y-3 max-w-[768px]">
          <div class="space-y-3">
            <p class="text-xl" v-text="context.title"></p>
            <p class="text-sm" v-html="context.description"></p>
          </div>
          <a
            href="#"
            class="text-green-300 font-semibold flex gap-2 items-center hover:underline"
          >
            <p>Полные условия использования</p>
            <IconPaper />
          </a>
        </div>
      </div>
      <div class="comments space-y-3">
        <Comments
          class="mt-12"
          root="album-videos"
          :id="context.id"
          :dependant="!false"
          :endpoint="`/albums/${route.params.albumId}/videos/${context.id}/comments`"
          :sendEndpointOverwrite="`/albums/${route.params.albumId}/videos/${context.id}/comment`"
        />
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { useHead } from 'unhead'
import { useSeoMeta } from 'unhead'
import { inject, onMounted, onUnmounted, ref } from 'vue'
import { useQuery } from 'vue-query'
import { useRoute, useRouter } from 'vue-router'

import { useAuthStore } from '@/store/auth.js'

import { IconDownload, IconHeart, IconShare3 } from '@tabler/icons-vue'

import Comments from '@/components/comments/Comments.vue'
import IconPaper from '@/components/icons/IconPaper.vue'
import Button from '@/components/ui/UIButton.vue'
import useModalDynamic from '@/plugins/useModalDynamic.js'
import { isObject } from '@/utils/isObject'
import { useReactions } from '@/utils/reactions.js'

import { useFileDownloader } from '../../utils/use/useFileDownload'
import VideoPlayer from '../VideoPlayer.vue'
import ShareModal from './ShareModal.vue'

interface Props {
  context: any
}

const props = defineProps<Props>()
const http = inject('http')
const route = useRoute()
const router = useRouter()

const queryKey = ['albums', props.context.id]
let meta = null

const { isDownloading, error, downloadFile } = useFileDownloader()
const resource = ref(null)
const href = ref('')
const videoSource = ref(``)

const { setReaction } = useReactions({
  auth: useAuthStore(),
  queryKey,
  rootKey: 'album-videos',
  rootParamId: props.context.id,
})

const { data: video } = useQuery({
  queryKey,
  queryFn: () =>
    http(`/albums/${route.params.albumId}/videos/${props.context.id}`),
})

const openShareModal = (link: string) => {
  useModalDynamic({
    opened: true,
    component: ShareModal,
    compAttrs: {
      context: {
        link,
      },
    },
  })
}

const clearRouteParams = () => {
  router.push(`/videos/${route.params.albumId}`)
}

const handleMessage = (event: MessageEvent) => {
  if (event.origin !== 'https://video-cdn.gov-murman.ru') {
    return
  }

  const response = JSON.parse(event.data)
  if (response.type === 'resources') {
    resource.value = response.data
    initMeta()
  }
}

const loadSource = () => {
  if (props.context.external_id) {
    videoSource.value = `https://video-cdn.gov-murman.ru/${props.context.external_id}`
  }
}

const sendReaction = async (type: Reaction) => {
  if (props.context) {
    const path = `/api/albums/${route.params.albumId}/videos/${props.context.id}/react`
    setReaction(path, type, 'albums')
  }
}

const initMeta = () => {
  meta = useHead({
    title: props.context.title || 'Смотри Заполярье',
    meta: [
      { property: 'og:video:title', content: props.context.title },
      {
        property: 'og:video:description',
        content: props.context.description,
      },
      {
        property: 'og:video:duration',
        content: resource.value.duration,
      },
      {
        property: 'og:video',
        content: `https://video-cdn.gov-murman.ru/${props.context.external_id}`,
      },
    ],
  })
}

const clearMeta = () => meta.dispose()

onMounted(() => {
  window.addEventListener('message', handleMessage)
  setTimeout(() => (href.value = window.location.href), 100)
  loadSource()
})

onUnmounted(() => {
  clearRouteParams()
  clearMeta()
  window.removeEventListener('message', handleMessage)
})
</script>
