import { useSeoMeta } from 'unhead'
import { computed, inject, ref, watch } from 'vue'
import { useQuery, useQueryClient } from 'vue-query'
import { onBeforeRouteLeave, useRoute } from 'vue-router'

import { useGlobalStore } from '@/store/global.js'

import { createTippyAuth, lzCompress, lzDecompress } from '@/utils/global.js'

export default function useInfinitePage(endpointRoot) {
  const http = inject('http')
  const route = useRoute()
  const client = useQueryClient()
  const global = useGlobalStore()
  const root = endpointRoot

  // Set seo from router.js
  useSeoMeta(route.meta.seo ?? {})
  // Set filters if filters exist
  const filters = ref(
    route.query.filters ? lzDecompress(route.query.filters) : {},
  )

  const settlements = global.getSettlements

  function getAdditional(
    key,
    withFilters = false,
    extendFilters = {},
    select = 'resources',
  ) {
    return useQuery({
      queryKey: [root, key],
      queryFn: () =>
        http(
          `/${root}/${key}`,
          withFilters
            ? {
                query: {
                  filters: lzCompress({ ...filters.value, ...extendFilters }),
                },
              }
            : {},
        ),
      select: (data) => data[select],
    })
  }

  function setItemSubscribe(id) {
    client.setQueryData(`${root}-index`, (old) => {
      const pages = old.pages.map((page) => {
        const resources = page.resources.map((res) =>
          res.id === id ? { ...res, subscribed: !res.subscribed } : res,
        )
        return { ...page, resources }
      })
      return {
        pages: pages,
        pageParams: old.pageParams,
      }
    })
  }

  function sub(root, id) {
    return http(`/${root}/${id}/subscribe`, {
      method: 'POST',
    })
  }

  function subscribe(id, show, element) {
    setItemSubscribe(id)
    sub(root, id).catch(() => {
      if (show) {
        show()
      } else if (element) {
        createTippyAuth(element, 'Чтобы добавить в избранное')
      }
      setItemSubscribe(id)
    })
  }

  // For filters restore
  onBeforeRouteLeave((to, from) => {
    if (to.name.startsWith(from.name)) {
      global.resources.filters = route.query?.filters
    } else {
      client.clear()
    }
  })

  const hasFilters = computed(() => {
    return Object.entries(filters.value).filter(([k, v]) => !!v).length > 0
  })

  return {
    route,
    root,
    settlements,
    filters,
    hasFilters,
    subscribe,
    getAdditional,
  }
}
