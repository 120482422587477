<template>
  <div v-if="global.resources.comments.enabled">
    <UIHeading :title="`Комментарии (${total})`" tag="3" class="mb-3" />

    <div class="max-w-[640px]">
      <div
        v-if="global.resources.comments.history_enabled"
        class="divide-y divide-black-200 dark:divide-black-600 [&>.hide-child-border+div]:!border-none"
      >
        <Comment
          v-for="item in data"
          :key="item.id"
          :context="item"
          :user-id="auth.user?.id"
          :reply-to="replyTo(item.model_id)"
          @set-reaction="sendCommentReaction"
          @get-replies="getReplies"
          @send-reply="sendReply"
          @remove="removeComment"
        />
      </div>

      <div v-if="global.resources.comments.can_be_published">
        <div v-if="auth.user" class="flex items-start mt-8">
          <!--        <div-->
          <!--          class="w-14 h-14 rounded-full flex flex-none mr-4 bg-black-100 relative z-10"-->
          <!--        >-->
          <!--          <IconCameraSelfie class="m-auto text-black-300" />-->
          <!--        </div>-->
          <div class="w-full flex mt-1">
            <textarea
              ref="textarea"
              v-model="input"
              class="resize-none flex-1 py-3 px-4 ring-1 ring-inset ring-black-200 focus:ring-primary dark:focus:ring-primary text-black dark:text-white dark:bg-primary-950 dark:ring-primary-900 outline-none rounded max-h-[250px]"
              placeholder="Напишите комментарий"
            />
            <button
              class="bg-primary rounded-lg size-12 flex ml-4 p-2.5"
              @click="sendComment"
            >
              <Transition mode="out-in">
                <IconSend
                  v-if="!isCommentSending"
                  class="text-white m-auto size-6"
                />
                <IconLoading v-else class="text-white m-auto size-6" />
              </Transition>
            </button>
          </div>
        </div>
        <div
          v-else
          class="p-4 rounded-md dark:bg-primary-950 bg-black-100 text-center mt-8"
        >
          <button @click="callAuthModal">
            <span class="text-primary">Авторизуйтесь</span>,
          </button>
          чтобы оставить комментарий
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTextareaAutosize } from '@vueuse/core'
import { notify } from 'notiwind'
import { inject, onBeforeUnmount, ref } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'

import { useAuthStore } from '@/store/auth.js'
import { useGlobalStore } from '@/store/global.js'

import { IconSend } from '@tabler/icons-vue'

import Comment from '@/components/comments/Comment.vue'
import IconLoading from '@/components/loading/LoadingIcon.vue'
import UIHeading from '@/components/ui/UIHeading.vue'
import useEcho from '@/plugins/echo.js'
import { callAuthModal } from '@/utils/global.js'

const { textarea, input } = useTextareaAutosize()

const global = useGlobalStore()

const props = defineProps({
  id: {
    type: [String, Number],
    default: null,
    required: true,
  },
  dependant: {
    type: Boolean,
    default: false,
  },
  sendEndpointOverwrite: {
    type: String,
    default: null,
    required: false,
  },
  endpoint: {
    type: String,
    default: null,
    required: true,
  },
  root: {
    type: String,
    default: null,
    required: true,
  },
})

const total = ref(0)
const client = useQueryClient()
const http = inject('http')
const echo = useEcho()
echo
  .channel(`${props.root}.${props.id}.comments`)
  .listen('.user-published-comment', ({ resource, parents }) => {
    // If comment is in the tree
    if (!!data.value.find((item) => item.id === resource.id)) return

    // Update comments array
    client.setQueryData([`${props.root}-comments`, props.id], (data) => {
      let newData = []
      // Check if comment is reply
      const parentIndex = data.findIndex(
        (item) => item.id === resource.model_id,
      )
      if (parents.length) {
        // Set comment level
        const parent = data.find((item) => item.id === resource.model_id)

        // Update parents comments_count
        newData = data.map((item) =>
          parents.includes(item.id)
            ? { ...item, comments_count: item.comments_count + 1 }
            : item,
        )

        // Set resource level
        resource.level = parent.level === null ? 1 : parent.level + 1

        // find end of reply
        let lastIndex = findIndexFromIndexToNull(
          newData,
          parentIndex,
          parent.level,
        )
        lastIndex !== -1
          ? newData.splice(lastIndex, 0, resource)
          : newData.splice(parentIndex + 1, 0, resource)
      } else {
        return [...data, resource]
      }

      return [...newData]
    })
  })
  .listen('.comment-was-deleted', ({ resource, parents }) => {
    client.setQueryData([`${props.root}-comments`, props.id], (old) => {
      let newData = old.slice()
      const fromIndex = old.findIndex((item) => item.id === resource.id)
      let lastIndex = findIndexFromIndexToNull(
        old,
        fromIndex,
        old[fromIndex].level,
      )
      // Remove tree
      newData.splice(fromIndex, lastIndex !== -1 ? lastIndex - fromIndex : 1)
      newData = newData.map((item) =>
        parents.includes(item.id)
          ? { ...item, comments_count: item.comments_count - 1 }
          : item,
      )
      return newData
    })
  })

const auth = useAuthStore()

function replyTo(model_id) {
  if (!model_id) return
  return data.value.find((item) => item.id === model_id)?.user
}

// Load comments
function useCommentsQuery() {
  return useQuery(
    [`${props.root}-comments`, props.id],
    () =>
      http(props.endpoint).then(({ resources, total: t }) => {
        total.value = t
        return resources
      }),
    {
      enabled: props.dependant,
    },
  )
}

const { isLoading, isError, data, error } = useCommentsQuery()
// Load comments end

// Mutate comment reaction
const mutateCommentReaction = useMutation(
  ({ id, type }) =>
    http(`/comments/${id}/react`, {
      method: 'POST',
      body: {
        type,
      },
    }),
  {
    onSuccess: ({ global, personal }, variables) => {
      // Boom baby!
      const updater = (item) => {
        return {
          ...item,
          reactions: {
            global,
            personal,
          },
        }
      }
      client.setQueryData([`${props.root}-comments`, props.id], (data) =>
        data.map((item) => (item.id === variables.id ? updater(item) : item)),
      )
    },
  },
)

function sendCommentReaction(id, type) {
  mutateCommentReaction.mutate(id, type)
}

// Mutate comment reaction end

// Send comment mutation
function useMutateComments() {
  return useMutation(
    ({ id, body }) =>
      http(props.sendEndpointOverwrite || `/${props.root}/${id}/comment`, {
        method: 'POST',
        body: {
          body,
        },
      }),
    {
      onSuccess: () => {
        input.value = ''
        total.value++
      },
      onError: ({ data, status }) => {
        if (data?.errors?.global && data?.errors?.global[0]) {
          notify({
            group: 'default',
            type: 'error',
            title: data?.errors?.global[0],
            text: data?.errors?.comment[0],
          })
          input.value = ''
        }

        if (status === 403) {
          notify({
            group: 'default',
            type: 'error',
            title: 'Доступ к комментариям ограничен',
            text: 'Свяжитесь с администратором, если считаете, что это уведомление было получено по ошибке',
          })

          auth.fetchUser()
          input.value = ''
        }
      },
    },
  )
}

const { isLoading: isCommentSending, mutate: addCommentMutate } =
  useMutateComments()

function sendComment() {
  addCommentMutate({ id: props.id, body: input.value })
}

// Send comment end

// Remove comment
function useMutateRemoveComment() {
  return useMutation(({ id, body }) =>
    http(`/comments/${id}`, {
      method: 'DELETE',
    }).then(() => {
      total.value--
    }),
  )
}

const { mutate: removeCommentMutate } = useMutateRemoveComment()

function removeComment(id) {
  removeCommentMutate({ id: id })
}

// Remove comment end

// getReplies start

const findIndexFromIndexToNull = (arr, startIndex, parentLevel) => {
  for (let i = startIndex + 1; i < arr.length; i++) {
    let index = arr.findIndex(
      (item, index) => index > startIndex && item.level === parentLevel,
    )
    if (index !== -1) {
      return index
    }
    if (parentLevel - 1 === 0) {
      parentLevel = null
    } else {
      parentLevel--
    }
  }
  return -1
}

function useMutateGetReplies() {
  return useMutation(({ id, isReply }) => http(`/comments/${id}/replies`), {
    onSuccess: ({ resources }, variables) => {
      // Boom baby!
      client.setQueryData([`${props.root}-comments`, props.id], (old) => {
        // clicked comment index
        let index = old.findIndex((item) => item.id === variables.id)

        let data = old.map((item, i) =>
          index === i ? { ...item, isReply: true } : item,
        )

        resources.forEach((item) => {
          item.isReply = true
          item.level = (data[index].level ?? 0) + item.level + 1
        })

        const mapTree = resources.map((item) => item.id)
        const filtered = data.filter((item) => !mapTree.includes(item.id))

        filtered.splice(index + 1, 0, ...resources)

        return filtered
      })

      variables.callback()
    },
  })
}

const { mutate: getRepliesMutate } = useMutateGetReplies()

function getReplies(id, callback) {
  // Comment index collapse
  let index = data.value.findIndex((item) => item.id === id)
  // If reply is open
  if (data.value[index].isReply) {
    client.setQueryData([`${props.root}-comments`, props.id], (old) => {
      const parentIndex = old.findIndex((item) => item.id === old[index].id)
      let lastIndex = findIndexFromIndexToNull(
        old,
        parentIndex,
        old[parentIndex].level,
      )
      const newData = old.map((item, i) =>
        index === i ? { ...item, isReply: false } : item,
      )

      // console.log("Parent index:", parentIndex, "Last index:", lastIndex);
      // console.log("Delete if not find", newData.length - parentIndex);
      newData.splice(
        index + 1,
        lastIndex !== -1
          ? lastIndex - (parentIndex + 1)
          : newData.length - 1 - parentIndex,
      )

      return [...newData]
    })
  } else {
    getRepliesMutate({ id: id, callback })
  }
}

// getReplies end
async function sendReply(id, comment) {
  await http(`/comments/${id}/reply`, {
    method: 'POST',
    body: {
      body: comment,
    },
  }).then(() => {
    total.value++
  })
}

onBeforeUnmount(() => {
  echo.leaveChannel(`${props.root}.${props.id}.comments`)
})
</script>
