// Get all media in collection using collection name.
import { sortBy } from 'lodash-es'

export const getMedia = (media, collection, defaultPath, onlyFirst = false) => {
  if (!Array.isArray(media) || media.length === 0) {
    if (onlyFirst)
      return {
        path: defaultPath,
        collection,
      }
    else return []
  }

  const group = sortBy(
    media.filter((i) => {
      return i.collection === collection || i.hasOwnProperty(collection)
    }),
    ['order'],
  )

  if (onlyFirst) {
    return group.length > 0
      ? group[0]
      : {
          path: defaultPath,
          collection,
        }
  }

  return group
}

// Get media path from collection.
export const getMediaPath = (media, collection, defaultPath) => {
  const m = getMedia(media, collection, defaultPath, true)
  if (m && m.hasOwnProperty(collection)) {
    return m[collection]
  }

  return m.path
}

export const getMediaPathItem = (item, collection) => {
  if (item && item.hasOwnProperty(collection)) {
    return item[collection]
  }
  return item.path
}

// Get media path collection.
export const getMediaPathList = (media, collection) => {
  if (!Array.isArray(media) || media.length === 0) {
    return []
  }

  const group = sortBy(
    media.filter((i) => {
      return i.collection === collection
    }),
    ['order'],
  )

  return group.map((el) => {
    return el.path
  })
}
