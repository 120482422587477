<template>
  <div class="video-page space-y-3 w-full">
    <iframe
      :src="src"
      class="bg-black rounded-lg cursor-pointer"
      width="100%"
      height="550"
    />
    <div class="actions space-y-3 md:flex justify-between md:flex-row-reverse">
      <div class="space-x-4">
        <Button
          size="xs"
          :type="
            video?.resource?.reactions?.personal.includes('lovely')
              ? 'green'
              : 'gray'
          "
          :icon="IconHeart"
          :title="video?.resource?.reactions?.global?.lovely"
          class="flex gap-2 text-sm"
          @click="sendReaction('lovely')"
        />
        <Button
          size="xs"
          type="gray"
          title="Поделиться"
          :icon="IconShare3"
          class="flex gap-2 text-sm"
          @click="openShareModal(href)"
        />
        <Button
          size="xs"
          type="gray"
          title="Скачать"
          :icon="IconDownload"
          class="flex gap-2 text-sm"
          @click="
            downloadFile(
              isObject(context.origins)
                ? Object.values(context.origins)[0]
                : context.origins[0],
            )
          "
        />
      </div>
      <div class="space-y-3 max-w-[458px]">
        <div class="space-y-3">
          <p class="text-xl" v-text="context.name"></p>
          <p class="text-sm" v-html="context.description"></p>
        </div>
        <a
          href="#"
          class="text-green-300 font-semibold flex gap-2 items-center hover:underline"
        >
          <p>Полные условия использования</p>
          <IconPaper />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSeoMeta } from 'unhead'
import { inject, onMounted, onUnmounted, ref } from 'vue'
import { useQuery } from 'vue-query'
import { useRoute } from 'vue-router'

import { useAuthStore } from '@/store/auth.js'

import { IconDownload, IconHeart, IconShare3 } from '@tabler/icons-vue'

import Button from '@/components/ui/UIButton.vue'
import { useReactions } from '@/utils/reactions.js'

import { isObject } from '../../utils/isObject.js'
import { useFileDownloader } from '../../utils/use/useFileDownload.js'
import IconPaper from '../icons/IconPaper.vue'

interface Props {
  src: string
  context: Resource
}

type Resource = {
  id: number
  name: string
  description: string | null
  external_id: string | null
  poster: string | null
  preview: string | null
  reactions: {
    global: {
      wow: number
      lovely: number
      sad: number
      angry: number
    }
    personal: string[]
  }
  tags: string[]
  visibility: boolean
}

const href = window.location.href
const props = defineProps<Props>()
const route = useRoute()
const http = inject('http')
const { isDownloading, error, downloadFile } = useFileDownloader()

const queryKey = ['album', props.context.id]

const resource = ref({})

const { setReaction } = useReactions({
  auth: useAuthStore(),
  queryKey,
  rootKey: 'album-videos',
  rootParamId: props.context.id,
})

const { data: video } = useQuery({
  queryKey,
  queryFn: () =>
    http(`/albums/${route.params.albumId}/videos/${props.context.id}`),
})

const sendReaction = async (type: Reaction) => {
  if (props.context) {
    const path = `/api/albums/${route.params.albumId}/videos/${props.context.id}/react`
    setReaction(path, type, 'album-videos')
  }
}

const openShareModal = (link: string) => {
  useModalDynamic({
    opened: true,
    component: ShareModal,
    compAttrs: {
      context: {
        link,
      },
    },
  })
}

const handleMessage = (event: MessageEvent) => {
  if (event.origin !== 'https://video-cdn.gov-murman.ru') {
    return
  }
  const response = JSON.parse(event.data)
  if (response.type === 'resources') resource.value = response.data
}

onMounted(async () => {
  window.addEventListener('message', handleMessage)
})

onUnmounted(() => {
  window.removeEventListener('message', handleMessage)
})
</script>
