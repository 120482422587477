<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.00012 15.0001L15.0001 9.00006M11.0001 6.00006L11.4631 5.46406C12.4009 4.52639 13.6728 3.99966 14.999 3.99976C16.3251 3.99985 17.5969 4.52676 18.5346 5.46456C19.4723 6.40237 19.999 7.67425 19.9989 9.00042C19.9988 10.3266 19.4719 11.5984 18.5341 12.5361L18.0001 13.0001M13.0001 18.0001L12.6031 18.5341C11.6544 19.4722 10.3739 19.9984 9.03964 19.9984C7.70535 19.9984 6.42489 19.4722 5.47614 18.5341C5.0085 18.0717 4.63724 17.5211 4.38385 16.9142C4.13047 16.3073 4 15.6562 4 14.9986C4 14.3409 4.13047 13.6898 4.38385 13.0829C4.63724 12.476 5.0085 11.9255 5.47614 11.4631L6.00014 11.0001"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
