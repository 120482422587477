<template>
  <footer class="bg-black-50 dark:bg-primary-1000 relative overflow-hidden">
    <!--Decoration-->
    <div class="absolute w-full bottom-0 left-0 z-0 dark:opacity-80">
      <img
        class="absolute left-0 bottom-0 z-10"
        src="/images/footer/left-left.svg"
        alt="mount-decoration"
      />
      <img
        class="absolute left-[90px] bottom-0 z-20"
        src="/images/footer/left-middle.svg"
        alt="mount-decoration"
      />
      <img
        class="absolute left-[430px] bottom-0 z-10"
        src="/images/footer/left-right.svg"
        alt="mount-decoration"
      />
      <img
        class="absolute right-0 bottom-0"
        src="/images/footer/right.svg"
        alt="mount-decoration"
      />
    </div>
    <div
      class="container relative py-12 sm:py-16 grid sm:grid-cols-3 gap-12 sm:gap-32"
    >
      <!-- Header -->
      <div class="col-span-1">
        <div class="flex items-center mb-10">
          <UILogo class="h-10 mr-4" />
        </div>

        <!-- Description -->
        <p class="mb-6">
          Портал для жителей Мурманской области инициирован губернатором
          Мурманской области А. В. Чибисом
        </p>

        <div class="flex flex-col justify-between mt-12">
          <p class="dark:text-gray-200 text-gray-700 text-sm">
            © {{ new Date().getFullYear() }} Наш Север
          </p>
          <div class="mt-8 sm:mt-12 sm:mb-24">
            <UIGov class="h-[44px]" />
          </div>
        </div>
      </div>

      <!-- Menu Sections -->
      <div
        class="grid col-span-2 grid-cols-2 sm:grid-cols-3 gap-8 md:mb-0 mb-48"
      >
        <!-- Blog Section -->
        <div class="grid gap-8 sm:!order-0 order-3">
          <FooterListBlock v-bind="global.resources?.menu?.projects" />
          <FooterListBlock
            title="Контакты"
            :children="[
              {
                title: 'support@nashsever51.ru',
                link: 'mailto:support@nashsever51.ru',
              },
            ]"
          />
          <FooterListBlock v-bind="global.resources?.menu?.contests" />
        </div>

        <div class="grid gap-8 sm:!order-1 order-1">
          <FooterListBlock v-bind="global.resources?.menu?.materials" />
          <FooterListBlock v-bind="global.resources?.menu?.activities" />
        </div>

        <!-- Services Section -->
        <div class="sm:!order-1 order-2">
          <FooterListBlock
            title="Сервисы"
            :children="global.resources.services"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { useGlobalStore } from '@/store/global.js'

import FooterListBlock from '@/components/common/FooterListBlock.vue'
import UIGov from '@/components/ui/UIGov.vue'
import UILogo from '@/components/ui/UILogo.vue'

const global = useGlobalStore()
</script>
