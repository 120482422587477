import { ref } from 'vue'

export function useFileDownloader() {
  const isDownloading = ref(false)

  const downloadFile = (url) => {
    if (!url) return

    isDownloading.value = true

    const link = document.createElement('a')
    link.href = url
    link.download = url.split('/').pop()
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    isDownloading.value = false
  }

  return { downloadFile, isDownloading }
}
