<template>
  <RouterLink class="relative z-0" :to="to">
    <div class="absolute z-[2] bottom-[52px] right-3 flex gap-3">
      <div
        v-if="context.count_videos !== undefined"
        class="chip absolute bottom-0 right-3 flex items-center gap-1 py-1 px-3 rounded-md bg-[#0C0C0E66]/20"
      >
        <IconVideo stroke="{2}" class="size-[18px]" />
        <p>{{ context.count_videos }}</p>
      </div>
      <div
        v-if="context.duration !== undefined"
        class="chip absolute bottom-5 right-3 flex items-center gap-1 py-1 px-3 rounded-md bg-[#0C0C0E66]/20"
      >
        <p>{{ formatTime(context.duration) }}</p>
      </div>
    </div>
    <figure class="group w-full flex flex-col gap-3">
      <ImagePlaceholder
        class="aspect-[384/256] group-hover:scale-[102%] ease-in-out duration-500 z-0 rounded-xl overflow-hidden"
        :source="
          getMediaPath(context.media, 'default-optimized') ||
          getMediaPath(context.media, 'default')
        "
        :preview="getMediaPath(context.media, 'preview')"
        lazy
        absolute
        :alt="context.title"
      />
      <figcaption class="space-y-1">
        <p class="md:text-lg" v-text="context.title"></p>
        <div
          class="subtitle line-clamp-1 text-xs md:text-sm"
          v-html="context.subtitle"
        ></div>
      </figcaption>
    </figure>
  </RouterLink>
</template>

<script setup lang="ts">
import { RouterLink } from 'vue-router'

import { IconVideo } from '@tabler/icons-vue'

//@ts-ignore
import ImagePlaceholder from '@/components/ImagePlaceholder.vue'
//@ts-ignore
import { getMediaPath } from '@/utils/media.js'

type Media = {
  id: number
  path: string
  relative_path: string
  size: number
  human_size: string
  name: string
  collection: string
  type: 'image'
  preview: string
  extension: 'jpg' | 'png'
  order: number
  video_source: null
  'default-optimized': string
}

type Context = {
  id: number
  title: string
  subtitle: string
  count_videos: number
  time_video: string
  media: Media[]
  tags: []
}

type Props = {
  to: string
  context: Context
}

defineProps<Props>()

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}
</script>

<style scoped>
.chip {
  backdrop-filter: blur(20px);
}

.subtitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-1 {
  -webkit-line-clamp: 1; /* Ограничиваем до 2 строк */
}
</style>
