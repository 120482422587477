<template>
  <div ref="container" class="flex items-center relative">
    <button
      class="rounded-full relative w-6 h-6 flex text-black-400 dark:text-black-200 transition-colors hover:text-primary"
      @click="isOpen = !isOpen"
    >
      <IconMoodSmileBeam class="w-full h-full" />
    </button>
    <Transition v-bind="fade" mode="out-in">
      <div
        v-show="isOpen"
        class="grid grid-cols-3 gap-0.5 p-1 w-[120px] rounded-lg ring-1 ring-primary bg-white dark:bg-primary-950 absolute -top-2 z-10"
        :class="position === 'right' ? 'left-8' : 'right-8'"
      >
        <button
          v-for="(item, i) in Object.keys(reactions)"
          :key="item"
          class="rounded transition-colors hover:bg-black-100 hover:dark:bg-brand-1"
          :class="{
            'bg-primary-200 dark:bg-brand-1 hover:bg-primary-300':
              personal.includes(item),
          }"
        >
          <dotlottie-player
            ref="players"
            class="scale-[1] group-hover:scale-[3] transition-all"
            :src="reactions[item]"
            :direction="direction"
            :controls="controls"
            :loop="false"
            :mode="playMode"
            :speed="speed"
            objectfit="fill"
            @mouseenter="
              () => {
                players[i].setLoop(true)
                players[i].play()
              }
            "
            @mouseleave="players[i].setLoop(false)"
            @click="setReaction(item)"
          />
        </button>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'

import { IconMoodSmileBeam } from '@tabler/icons-vue'

import LovelyLottie from '@/assets/lottie/lovely.json?url'
import SadLottie from '@/assets/lottie/sad.json?url'
import WowLottie from '@/assets/lottie/wow.json?url'
import { fade } from '@/utils/transitions.js'

const container = ref(null)
const isOpen = ref(false)

onClickOutside(container, (event) => {
  isOpen.value = false
})

defineProps({
  global: {
    type: Object,
    default: {},
  },
  personal: {
    type: Array,
    default: [],
  },
  position: {
    type: String,
    default: 'right',
  },
})
const emits = defineEmits(['set-reaction'])

const players = ref([])
const speed = ref(2)
const direction = ref(1)
const playMode = ref('normal')
const autoplay = ref(false)
const controls = ref(false)
const reactions = ref({
  lovely: LovelyLottie,
  wow: WowLottie,
  sad: SadLottie,
})

function setReaction(key) {
  isOpen.value = false
  emits('set-reaction', key)
}
</script>
