<template>
  <div
    class="relative flex items-center justify-center overflow-hidden"
    :class="classPlaceholder"
  >
    <img
      v-if="preview && !previewError"
      :alt="attrs?.alt"
      :src="previewToDataURL(preview)"
      class="w-full h-full object-cover object-center absolute inset-0"
      @error="checkPreview"
    />
    <img
      v-if="source && !hasError"
      :alt="alt"
      :src="source"
      class="h-full w-full object-cover transition-opacity ease-in-out duration-500 z-10"
      :class="[
        isLoaded ? 'opacity-100' : 'opacity-0',
        { absolute: absolute },
        objectPosition,
      ]"
      :loading="lazy ? 'lazy' : 'eager'"
      @error="onError"
      @load="onLoaded"
    />
    <div
      v-if="(!source || hasError) && (!preview || previewError)"
      class="w-full h-full flex items-center justify-center"
      :class="{ absolute: absolute }"
    >
      <img
        v-if="!placeholderImage && !placeholder"
        src="/images/image_placeholder.svg"
        alt="нет изображения"
        class="w-full h-full object-cover"
      />
      <component
        v-else-if="!placeholderImage"
        :is="placeholder"
        class="h-[20%] w-[20%]"
        :class="classIcon"
      />
    </div>
    <img
      v-if="placeholderImage && hasError"
      :alt="alt"
      :src="placeholderImage"
      class="h-full w-full object-cover object-center"
      :class="{ absolute: absolute }"
      :loading="lazy ? 'lazy' : 'eager'"
      @error="onError"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useAttrs } from 'vue'

import { previewToDataURL } from '@/utils/thumbhash.js'

const attrs = useAttrs()

defineProps({
  absolute: {
    type: Boolean,
    default: false,
  },
  source: {
    type: String,
    default: '',
  },
  preview: {
    type: String,
    default: null,
  },
  alt: {
    type: String,
    default: '',
  },
  placeholder: {
    type: [Object, String],
    default: null,
  },
  placeholderImage: {
    type: String,
    default: null,
  },
  classPlaceholder: {
    type: String,
    default: 'bg-black-50 dark:bg-primary-950',
  },
  classIcon: {
    type: String,
    default: 'text-black-200 dark:text-black-400',
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  objectPosition: {
    type: String,
    default: 'object-center',
  },
})
const isLoaded = ref(false)
const isRendered = ref(false)
const hasError = ref(false)
const previewError = ref(false)

function onLoaded() {
  isLoaded.value = true
}

function onError() {
  isLoaded.value = false
  hasError.value = true
}

function checkPreview() {
  previewError.value = true
}

onMounted(() => {
  isRendered.value = true
})
</script>
