<template>
  <div
    ref="containerRef"
    class="flex items-center gap-2 min-h-[40px]"
    :class="{ 'flex-row-reverse': position === 'left' }"
  >
    <ReactionsItems
      :global="global"
      :personal="personal"
      :position="position"
      @set-reaction="setReaction"
    />

    <div
      v-if="Object.keys(filteredGlobal).length"
      class="rounded-xl pb-2 pt-2 flex gap-2 w-min"
    >
      <button
        v-for="item in Object.keys(filteredGlobal)"
        :key="item"
        class="relative group min-w-[32px] min-h-[24px] cursor-pointer ring-1 flex items-center px-2 rounded transition-shadow"
        :class="
          personal.includes(item)
            ? 'ring-primary-400 dark:ring-brand-1 dark:bg-brand-1/20 bg-primary-200 hover:bg-primary-300'
            : 'ring-black-300 dark:bg-black/50 dark:text-white bg-black-100 hover:bg-black-200'
        "
        @click="setReaction(item)"
      >
        <dotlottie-player
          :ref="(el) => pushRef(el, item)"
          class="absolute scale-[1.6] group-hover:scale-[3] h-4 w-4 transition-all"
          :src="reactions[item]"
          :direction="direction"
          :controls="controls"
          :loop="false"
          :mode="playMode"
          :speed="speed"
          :subframe="true"
          objectfit="fill"
          @mouseenter="
            () => {
              players[item].setLoop(true)
              players[item].play()
            }
          "
          @mouseleave="players[item].setLoop(false)"
        />
        <span
          v-if="global[item]"
          class="pointer-events-none text-xs pl-6 py-1 transition-colors"
          :class="
            personal.includes(item)
              ? 'text-primary'
              : 'text-black dark:text-white'
          "
          v-text="global[item]"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, h, ref } from 'vue'
import { useTippy } from 'vue-tippy'

import LovelyLottie from '@/assets/lottie/lovely.json?url'
import SadLottie from '@/assets/lottie/sad.json?url'
import WowLottie from '@/assets/lottie/wow.json?url'
import ReactionsItems from '@/components/ReactionsItems.vue'
import UIAuthTippy from '@/components/ui/UIAuthTippy.vue'
import { isAuthorized } from '@/utils/global.js'

const props = defineProps({
  global: {
    type: Object,
    default: {},
  },
  personal: {
    type: Array,
    default: [],
  },
  position: {
    type: String,
    default: 'right',
  },
})

const emits = defineEmits(['set-reaction'])

const containerRef = ref(null)

function pushRef(e, i) {
  players.value[i] = e
}

const filteredGlobal = computed(() => {
  let obj = { ...props.global }
  for (const key in obj) {
    if (obj[key] === 0) {
      delete obj[key]
    }
  }
  return obj
})

const { hide: hideReactionTippy, show: showReactionTippy } = useTippy(
  containerRef,
  {
    content: h(UIAuthTippy, {
      body: 'Чтобы оставить реакцию',
      hide: () => hideReactionTippy(),
    }),
    trigger: 'manual',
    interactive: true,
  },
)

const reactions = {
  lovely: LovelyLottie,
  wow: WowLottie,
  sad: SadLottie,
}

const players = ref({})
const speed = ref(2)
const direction = ref(1)
const playMode = ref('normal')
const autoplay = ref(false)
const controls = ref(false)

function setReaction(key) {
  if (isAuthorized()) {
    emits('set-reaction', key)
  } else {
    showReactionTippy()
  }
}
</script>
