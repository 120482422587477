<template>
  <div
    class="modal text-white space-y-5 m-auto max-w-[720px] w-full bg-black rounded-lg overflow-hidden p-10"
  >
    <p class="text-2xl font-bold">Прямая ссылка</p>
    <div class="flex gap-3">
      <FormKit
        v-model="context.link"
        type="text"
        :classes="{
          outer: 'h-[52px] w-full !m-0',
          inner: 'h-full',
          input: 'h-full',
          wrapper: 'h-full',
          form: 'flex-1',
        }"
      />
      <UIButton
        type="green"
        title="Скопировать"
        class="max-w-[140px] w-full"
        @click="copyToClipboard"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { notify } from 'notiwind'

import UIButton from '@/components/ui/UIButton.vue'

interface Props {
  context: {
    link: string
  }
}
const props = defineProps<Props>()

function copyToClipboard(): void {
  if (!navigator.clipboard || !navigator.clipboard.writeText) {
    console.error('Clipboard API не поддерживается в этом браузере или среде.')
    notify({
      group: 'default',
      type: 'error',
      title: 'Ошибка: Clipboard API не поддерживается',
    })
    return
  }

  navigator.clipboard
    .writeText(props.context.link)
    .then(() => {
      notify({
        group: 'default',
        type: 'success',
        title: 'Текст успешно скопирован в буфер обмена',
      })
      console.log('Текст успешно скопирован!')
    })
    .catch((err) => {
      console.error('Ошибка при копировании текста: ', err)
      notify({
        group: 'default',
        type: 'error',
        title: 'Ошибка при копировании текста',
      })
    })
}
</script>
