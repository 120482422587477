<template>
  <div>
    <h2 class="mb-4 text-xl font-semibold" v-text="title" />
    <div class="text-black-700 dark:text-black-300 text-sm space-y-2">
      <div v-for="link in children">
        <router-link
          v-if="!isExternal(link.link)"
          :to="link.link"
          class="block"
          v-text="link.title"
        />
        <a
          v-else
          :href="link.link"
          class="block"
          v-text="link.title"
          target="_blank"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  title: {
    type: String,
  },
  children: {
    type: Array,
  },
})

function isExternal(link) {
  return (
    link.startsWith('http') ||
    link.startsWith('tel:') ||
    link.startsWith('mailto:')
  )
}
</script>
