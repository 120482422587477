<template>
  <div class="flex">
    <button
      v-if="pagination.length > 1"
      class="flex items-center text-white-20 hover:text-secondary hover:[&>span]:border-secondary"
      :disabled="isFirstPage"
      @click="prev"
    >
      <IconArrowNarrowLeft class="transition-colors stroke-1" />
      <span class="border-b transition-colors" v-text="'Туда'" />
    </button>
    <div class="flex space-x-1" :class="{ 'mx-4': pagination.length > 1 }">
      <button
        v-for="item in pagination"
        :key="item"
        :disabled="currentPage === item"
        class="w-12 md:w-[58px] h-9 rounded-lg ring-1 ring-inset font-bold"
        :class="[
          currentPage === item
            ? 'ring-primary text-white bg-primary'
            : 'bg-white-5 ring-white-5 text-secondary',
          { hidden: !item },
        ]"
        @click="currentPage = item"
      >
        {{ item }}
      </button>
    </div>
    <button
      v-if="pagination.length > 1"
      class="flex items-center text-gray-400 hover:text-secondary hover:[&>span]:border-secondary"
      :disabled="isLastPage"
      @click="next"
    >
      <span class="border-b transition-colors" v-text="'Сюда'" />
      <IconArrowNarrowLeft class="-rotate-180 transition-colors stroke-1" />
    </button>
  </div>
</template>

<script setup>
import { useOffsetPagination } from '@vueuse/core'
import { computed } from 'vue'

import { IconArrowNarrowLeft } from '@tabler/icons-vue'

const props = defineProps({
  page: {
    type: Number,
    default: 1,
  },
  total: {
    type: Number,
    default: 100,
  },
  per_page: {
    type: Number,
    default: 0,
  },
})

const emits = defineEmits(['page-change', 'page-size-change'])

const {
  currentPage,
  currentPageSize,
  pageCount,
  isFirstPage,
  isLastPage,
  prev,
  next,
} = useOffsetPagination({
  total: props.total,
  page: props.page,
  pageSize: props.per_page,
  onPageChange: ({ currentPage }) => emits('page-change', currentPage),
  onPageSizeChange: ({ currentPageSize }) =>
    emits('page-size-change', currentPageSize),
})

const pagination = computed(() => {
  const arr = Array.from({ length: pageCount.value }, (_, i) => i + 1)
  const length = 2
  let index = arr.indexOf(currentPage.value)

  if (index === -1) {
    return []
  }

  let lowerBound = Math.max(index - length + 1, 0)
  let upperBound = Math.min(index + length, arr.length)

  let result = arr.slice(lowerBound, upperBound)

  if (result.length === length) {
    let i = result.indexOf(currentPage.value)
    if (i === 0) {
      result.push(arr[index + length])
    }
    if (i === result.length - 1) {
      result.unshift(arr[index - length])
    }
  }
  return result.filter((item) => item)
})
</script>
