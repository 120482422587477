import { inject, onBeforeUnmount } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'

import useEcho from '@/plugins/echo.js'

export function useReactions({
  auth,
  rootKey,
  rootParamId,
  queryKey,
  queryClient,
}) {
  const echo = useEcho()
  const client = queryClient ? queryClient : useQueryClient()
  const http = inject('http')
  console.log(`${rootKey}.${rootParamId}.reacts`)

  echo
    .channel(`${rootKey}.${rootParamId}.reacts`)
    .listen('.user-reacted-record', (e) => {
      console.log('user-reacted-record', e)

      client.setQueryData(queryKey, (data) => {
        const modifyValue = (obj, key, newValue) => {
          return { ...obj, [key]: newValue }
        }
        const newObj = modifyValue(
          data.resource.reactions.global,
          e.type,
          data.resource.reactions.global[e.type] + 1,
        )
        let newPersonal = [...data.resource.reactions.personal]

        if (auth.user.id === e.user_id) {
          newPersonal.push(e.type)
        }

        return {
          ...data,
          resource: {
            ...data.resource,
            reactions: {
              personal: newPersonal,
              global: newObj,
            },
          },
        }
      })
    })
    .listen('.user-deleted-reaction-from-record', (e) => {
      client.setQueryData(queryKey, (data) => {
        const modifyValue = (obj, key, newValue) => {
          return { ...obj, [key]: newValue }
        }
        const newObj = modifyValue(
          data.resource.reactions.global,
          e.type,
          data.resource.reactions.global[e.type] - 1,
        )
        let newPersonal = [...data.resource.reactions.personal].filter(
          (i) => i !== e.type,
        )

        return {
          ...data,
          resource: {
            ...data.resource,
            reactions: {
              personal: newPersonal,
              global: newObj,
            },
          },
        }
      })
    })

  const updateReactionState = useMutation(({ path, type, qKey }) =>
    http(path, {
      method: 'POST',
      body: {
        type,
      },
    }),
  )

  function setReaction(path, value, qKey) {
    updateReactionState.mutate({
      path: path,
      type: value,
      qKey: qKey,
    })
  }

  return {
    setReaction,
  }
}
